import React from 'react'
import {SEO, Button, Datepicker, Input} from 'components'
import {Layout, BackButton} from 'widgets'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from 'reducers'
import {CentralAmericanMap,EuropeMap, NationalMap, NorthAmericaMap, OffMap, SouthAmericanMap, WorldMap} from 'images'
import moment from 'moment'
import {navigate} from 'gatsby'
import {Destiny, QuoteRequest} from 'models'
import {DESTINIES as destinies, quitLoading, setLoading, showError, useWindowSize} from 'utils'
import { CandidateService } from 'services'

type Form = {
    destiny_id: string,
    since: Date | undefined,
    until: Date | undefined,
    passengers: Array<number>
    isOldAge: boolean
}
const SecondStep = () => {
    const [width, height] = useWindowSize()
    const firstStep = useSelector((state: RootState) => state.quoteRequest)
    const dispatch = useDispatch()
    const defaultAge = 18
    const IFormState: Form = {
        destiny_id: 'world',
        since: undefined,
        until: undefined,
        passengers: [defaultAge],
        isOldAge: false
    }
    const [map, setMap] = React.useState(WorldMap)
    const [form, setForm] = React.useState<Form>(IFormState)
    const steps = {
        first: 1,
        second: 2,
        third: 3
    }
    const [step, setStep] = React.useState<number>(steps.first)
    const [quoteRequest, setQuoteRequest] = React.useState<QuoteRequest | null>(firstStep)
    const urlSearchParams = new URLSearchParams(window.location.search)
    const queryParams = Object.fromEntries(urlSearchParams.entries())
    const isFirstStep = step === steps.first
    const isSecondStep = step === steps.second
    const isThirdStep = step === steps.third
    const changeMapFromDestiny = (element: string) => {
        setLoading()
        switch (element) {
            case 'nacional':
                setMap(NationalMap)
                break
            case 'europe':
                setMap(EuropeMap)
                break
            case 'northAmerica':
                setMap(NorthAmericaMap)
                break
            case 'centralAmerica':
                setMap(CentralAmericanMap)
                break
            case 'southAmerica':
                setMap(SouthAmericanMap)
                break
            case 'world':
                setMap(WorldMap)
                break
            default:
                setMap(OffMap)
                break
        }
        quitLoading()
    }
    const selectDestiny = (element: string) => {
        const _form = {
            ... form,
            destiny_id: element
        }
        changeMapFromDestiny(element)
        setForm(_form)
        setStep(steps.second)
    }
    const onSelectedDates = () => {
        setStep(steps.third)
    }
    const addPassenger = () => {
        let passengers = [...form.passengers]
        passengers.push(defaultAge)
        const _form = {
            ... form,
            passengers
        }
        setForm(_form)
    }
    const deleteLastPassenger = () => {
        let passengers = [...form.passengers]
        passengers.splice(passengers.length-1, 1)
        const _form = {
            ... form,
            passengers
        }
        setForm(_form)
    }
    const setPassengerAge = (value: string, i: number) => {
        let passengers = [... form.passengers]
        passengers[i] = parseInt(value)
        const _form = {
            ... form,
            passengers
        }
        setForm(_form)
    }
    const change = (key: keyof Form, value: string | Date | undefined) => {
        const _form: Form = {
            ... form,
            [key]: value
        }
        setForm(_form);
    };

    const load = async () => {
        if(queryParams?.candidate){
            const candidate = await CandidateService.get(queryParams.candidate)
            let destiny_id = ''
            switch (candidate.destino) {
                case 'Mundo':
                    destiny_id = 'world'
                    break;
                case 'Norte América':
                    destiny_id = 'northAmerica'
                    break;
                case 'Sur América':
                    destiny_id = 'southAmerica'
                    break;
                case 'Europa':
                    destiny_id = 'europe'
                    break;
                case 'Central América':
                    destiny_id = 'centralAmerica'
                    break;
                case 'Nacional':
                    destiny_id = 'nacional'
                    break;
                default:
                    destiny_id = ''
                    break;
            }
            const country = candidate.intl_tel_input_data_value
            const country_phone = candidate.whatsapp.replace('+'+(candidate.intl_tel_input_data_value?.dialCode || ''), '')
            const concat: QuoteRequest = {
                ... quoteRequest,
                destiny_id,
                name: quoteRequest?.name || '',
                lastname: quoteRequest?.lastname || '',
                email: candidate.email,
                since: moment(candidate.fecha_salida).toDate(),
                until: moment(candidate.fecha_regreso).toDate(),
                country_phone,
                country
            }
            setQuoteRequest(concat)
            dispatch({
                type: 'SET_QUOTE_REQUEST',
                payload: concat
            })
        }
    }

    React.useEffect(() => {
        load()
    }, [])
    
    const search = async () => {
        try {
            setLoading()
            const _form: QuoteRequest = {
                ... quoteRequest,
                ... form,
                country: quoteRequest?.country || null,
                name: quoteRequest?.name || firstStep?.name || '',
                lastname: quoteRequest?.lastname || firstStep?.lastname || '',
                email: quoteRequest?.email || '',
                passengers: form?.passengers || quoteRequest?.passengers || [],
                country_phone: '+'+firstStep?.country?.dialCode+firstStep?.country_phone
            }
            const res = await CandidateService.save(_form, queryParams.candidate)
            dispatch({
                type: 'SET_QUOTE_REQUEST',
                payload: form
            })
            navigate('/buscar?candidate='+res.id_sql)
        } catch (error:any) {
            showError(error?.response?.data?.message)
        }finally{
            quitLoading()
        }
    }
    return(
        <Layout>
            <SEO
                title="Cotiza tu seguro de viajes en pocos pasos con Compara Assist."
                description="Somos la forma segura y sencilla para consultar las distintas opciones de seguros de viajes y de salud."
                keywords="cotizar seguro de viajes, cotizar seguro, ofertas de seguros de viajes, seguro de viaje nacional, seguro de viajes internacional, viajar a europa, viajar a estados unidos, seguro de viaje estados unidos, seguro de viaje europa, viajar a asia, seguro de viajes asia"
            />
            <BackButton
                navigateTo="/"
            />
            <div className="second-step">
                <div className="step-information">
                    <div className="container">
                        <h5 className="text-white text-light text-center">
                            ¡Excelente {quoteRequest?.name + ' '+ quoteRequest?.lastname}!. <br/>
                            Sigue los siguientes pasos:
                        </h5>
                    </div>
                </div>
                <div className="item-tabs">
                    <div className="container">
                        <div className="row">
                            <div className="col-md col-sm-12">
                                <p
                                    onClick={() => setStep(steps.first)}
                                    className={"text-purple ellipsis "+(isFirstStep && 'selected')}
                                >
                                    Tu destino
                                </p>
                            </div>
                            <div className="col-md col-sm-12">
                                <p
                                    onClick={() => (step >= steps.second || !!form.destiny_id ) && setStep(steps.second)}
                                    className={"text-purple ellipsis "+(isSecondStep && 'selected')}
                                >
                                    ¿Cuando viajarás?
                                </p>
                            </div>
                            <div className="col-md col-sm-12">
                                <p
                                    onClick={() => (step >= steps.third || (form.destiny_id && form.since && form.until)) && setStep(steps.third)}
                                    className={"text-purple ellipsis "+(isThirdStep && 'selected')}
                                >
                                    ¿Cuantas personas viajarían?(incluyendote)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item-tabs-body">
                    <div className="body-info">
                        <div className="row">
                            {
                                (width >= 1080 || isFirstStep) && (
                                    <div className={"item col col-md col-sm-12 "+(isFirstStep && ' enabled')}>
                                        <div className="container">
                                            <img
                                                src={map}
                                                alt={form.destiny_id || 'Mapa mundial'}
                                            />
                                            <p className="text-gray p-0 w-100 text-center m-0 font-weight-bold">
                                                Seleccioné su destino
                                            </p>
                                            <div className={"bordered "+(isFirstStep ? 'bg-purple' : 'bg-dark-gray')}>
                                                <div className="container">
                                                    <div className="row p-1">
                                                        {
                                                            destinies.map((element: Destiny, i: number) => {
                                                                return(
                                                                    <div
                                                                        key={i}
                                                                        className="col-md-4 col-sm-6 p-2"
                                                                    >
                                                                        <Button
                                                                            disabled={!isFirstStep}
                                                                            label={element.es}
                                                                            className={"white p-2 rounded-pill btn-shadow "+(element.slug === form.destiny_id && 'btn-selected')}
                                                                            onClick={() => selectDestiny(element.slug)}
                                                                        />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                (width >= 1080 || isSecondStep) && (
                                    <div className={"item col col-md col-sm-12 center-card"+ (isSecondStep && ' enabled')}>
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="container">
                                                    <div className="w-100 text-center">
                                                        <h4 className="card-title m-0 p-0">Fecha</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <Datepicker
                                                    disabled={!isSecondStep}
                                                    minDate={moment().toDate()}
                                                    value={form.since}
                                                    onChange={(value: Date) => change('since', value)}
                                                    label="Fecha de salida"
                                                    labelColor="text-purple"
                                                    required
                                                />
                                                <Datepicker
                                                    disabled={!isSecondStep}
                                                    minDate={form.since || moment().toDate()}
                                                    value={form.until}
                                                    onChange={(value: Date) => change('until', value)}
                                                    label="Fecha de llegada"
                                                    labelColor="text-purple"
                                                    required
                                                />
                                                {
                                                    (form.since || form.until) && (
                                                        <div className="bg-blue second-step-text">
                                                            <p className="card-text text-blue text-left w-100 p-3 second-step-text">
                                                                {
                                                                    form.since && (
                                                                        'Saldrias el '+moment(form.since).format('DD MMMM [del] YYYY')
                                                                    )
                                                                }
                                                                {
                                                                    form.until && (
                                                                        ' y llegarias el '+moment(form.until).format('DD MMMM [del] YYYY')
                                                                    )
                                                                }
                                                                <p className="text-blue font-weight-bold w-100 text-center p-1">
                                                                    {
                                                                        form.since && form.until && (
                                                                            'Viajarás '+(moment(form.until).diff(form.since, 'days')+1)+' días'
                                                                        )
                                                                    }
                                                                </p>
                                                            </p>
                                                        </div>
                                                    )
                                                }
                                                <div className="w-100 text-center p-2">
                                                    <Button
                                                        disabled={!isSecondStep || !form.since && !form.until}
                                                        onClick={() => onSelectedDates()}
                                                        label="Continuar"
                                                        className="success rounded-pill w-100 p-2 bordered"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                (width >= 1080 || isThirdStep) && (
                                    <div className={"item col col-md col-sm-12"+(isThirdStep && ' enabled')}>
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="container">
                                                    <div className="w-100 text-center">
                                                        <h4 className="card-title m-0 p-0">Pasajeros</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <h4 className="text-dark w-100 text-center">
                                                    Hasta 75 años
                                                </h4>
                                                <div className="container">
                                                    <div className="w-100 text-center">
                                                        <p className="text-gray">
                                                            Edades de pasajeros
                                                        </p>
                                                        <div className="row justify-content-center">
                                                            {
                                                                form.passengers.map((element: number | string, i: number) => {
                                                                    return(
                                                                        <div
                                                                            className="col-md-3 col-sm-4"
                                                                            key={i}
                                                                        >
                                                                            <Input
                                                                                disabled={!isThirdStep}
                                                                                name="passenger_age"
                                                                                className="pr-0"
                                                                                onChange={(value: string) => {
                                                                                    setPassengerAge(value, i)
                                                                                }}
                                                                                value={element.toString()}
                                                                                onBlur={() => {
                                                                                    if(!element){
                                                                                        setPassengerAge(defaultAge.toString(), i)
                                                                                    }else if(element && element.toString().length > 2){
                                                                                        const value = element.toString().substring(0, 2)
                                                                                        setPassengerAge(value, i)
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col col-md col-sm text-right">
                                                                <Button
                                                                    disabled={step !== steps.third || form.passengers.length === 1}
                                                                    className="link text-purple"
                                                                    icon="minus"
                                                                    onClick={() => deleteLastPassenger()}
                                                                />
                                                            </div>
                                                            <div className="col col-md col-sm text-left">
                                                                <Button
                                                                    disabled={step !== steps.third || form.passengers.length === 5}
                                                                    className="link text-purple"
                                                                    icon="plus"
                                                                    onClick={() => addPassenger()}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-100 text-center p-2">
                                                    <Button
                                                        disabled={!isThirdStep || form.passengers.some(element => !element)}
                                                        onClick={() => search()}
                                                        label="Iniciar busqueda >"
                                                        className="success rounded-pill w-100 p-1 bordered"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default SecondStep